import { createEffect, createSignal, For } from 'solid-js'
import './App.css'
import { RotateCCW, RotateCW, Tile, TileContentArrowDoubleFourtyFive, TileContentArrowDoubleNinety, TileContentArrowDoubleOneHundredEighty, TileContentArrowDoubleOneHundredThirtyFive, TileContentArrowSingle, TileContentSkip } from './Tile';
import { getBoard, getBoardSize, resetBoard, serializeBoard } from './models/board';

export enum ClickAction {
  RotateCW,
  RotateCCW,
  SetTileType_ColorGreen,
  SetTileType_ColorRed,
  SetTileType_ColorBlue,
  SetTileType_ColorYellow,
  SetTileType_Empty,
  SetTileType_ArrowSingle,
  SetTileType_ArrowDoubleFourtyFive,
  SetTileType_ArrowDoubleNinety,
  SetTileType_ArrowDoubleOneHundredEighty,
  SetTileType_ArrowDoubleOneHundredThirtyFive,
  SetTileType_ArrowSkip,
}

const [getSelectedClickAction, setSelectedClickAction] = createSignal<ClickAction>(ClickAction.SetTileType_ArrowSingle);
export { getSelectedClickAction };

function App() {
  createEffect(() => {
    localStorage.setItem('board', serializeBoard(getBoard()));
  });

  const gridStyle = () => {
    return `display: grid;grid-template-columns: repeat(${getBoardSize().width}, auto); grid-template-rows: repeat(${getBoardSize().height}, auto); gap: 2px`;
  }

  const getCursorClass = () => {
    const action = getSelectedClickAction();
    if (action === ClickAction.RotateCW)
      return 'cursor-rotate-cw';
    else if (action === ClickAction.RotateCCW)
      return 'cursor-rotate-ccw';

    return '';
  }

  return (
    <>
      <div class="controls">
        {/* <button style={{ margin: '10px' }} onClick={saveHtmlAsPDF}>Save PDF</button> */}
        <div class="button-group">
          <button aria-pressed={getSelectedClickAction() === ClickAction.SetTileType_ColorGreen} onClick={() => setSelectedClickAction(ClickAction.SetTileType_ColorGreen)}>
            🟩<br /><span>Green</span>
          </button>
          <button aria-pressed={getSelectedClickAction() === ClickAction.SetTileType_ColorRed} onClick={() => setSelectedClickAction(ClickAction.SetTileType_ColorRed)}>
            🟥<br /><span>Red</span>
          </button>
          <button aria-pressed={getSelectedClickAction() === ClickAction.SetTileType_ColorBlue} onClick={() => setSelectedClickAction(ClickAction.SetTileType_ColorBlue)}>
            🟦<br /><span>Blue</span>
          </button>
          <button aria-pressed={getSelectedClickAction() === ClickAction.SetTileType_ColorYellow} onClick={() => setSelectedClickAction(ClickAction.SetTileType_ColorYellow)}>
            🟨<br /><span>Yellow</span>
          </button>

          <button
            aria-pressed={getSelectedClickAction() === ClickAction.SetTileType_Empty}
            onClick={() => setSelectedClickAction(ClickAction.SetTileType_Empty)}
          >
            ⬜️<br /><span>Clear</span>
          </button>
        </div>

        <div class="button-group">
          <button
            aria-pressed={getSelectedClickAction() === ClickAction.SetTileType_ArrowSingle}
            onClick={() => setSelectedClickAction(ClickAction.SetTileType_ArrowSingle)}
          >
            <TileContentArrowSingle /><br />Single
          </button>
          <button
            aria-pressed={getSelectedClickAction() === ClickAction.SetTileType_ArrowDoubleFourtyFive}
            onClick={() => setSelectedClickAction(ClickAction.SetTileType_ArrowDoubleFourtyFive)}
          >
            <TileContentArrowDoubleFourtyFive /><br />45° Double
          </button>
          <button
            aria-pressed={getSelectedClickAction() === ClickAction.SetTileType_ArrowDoubleNinety}
            onClick={() => setSelectedClickAction(ClickAction.SetTileType_ArrowDoubleNinety)}
          >
            <TileContentArrowDoubleNinety /><br />90° Double
          </button>
          <button
            aria-pressed={getSelectedClickAction() === ClickAction.SetTileType_ArrowDoubleOneHundredEighty}
            onClick={() => setSelectedClickAction(ClickAction.SetTileType_ArrowDoubleOneHundredEighty)}
          >
            <TileContentArrowDoubleOneHundredEighty /><br />180° Double
          </button>
          <button
            aria-pressed={getSelectedClickAction() === ClickAction.SetTileType_ArrowDoubleOneHundredThirtyFive}
            onClick={() => setSelectedClickAction(ClickAction.SetTileType_ArrowDoubleOneHundredThirtyFive)}
          >
            <TileContentArrowDoubleOneHundredThirtyFive /><br />135° Double
          </button>
          <button
            aria-pressed={getSelectedClickAction() === ClickAction.SetTileType_ArrowSkip}
            onClick={() => setSelectedClickAction(ClickAction.SetTileType_ArrowSkip)}
          >
            <TileContentSkip /><br />Skip
          </button>
        </div>

        <div class="button-group">
          <button
            aria-pressed={getSelectedClickAction() === ClickAction.RotateCW}
            onClick={() => setSelectedClickAction(ClickAction.RotateCW)}
          >
            <RotateCW /><br />Rotate CW
          </button>
          <button
            aria-pressed={getSelectedClickAction() === ClickAction.RotateCCW}
            onClick={() => setSelectedClickAction(ClickAction.RotateCCW)}
          >
            <RotateCCW /><br />Rotate CCW
          </button>
        </div>

        <div class="button-group">
          <button class="print" onClick={() => {
            window.print();
          }}><Print /><br />Print</button>
          <button class="reset" onClick={resetBoard}><Warning /><br />Reset</button>
        </div>

      </div >
      <div id="workspace" class={getCursorClass()}>
        <div id="page">
          <div class="board cursor-rotate-cw" style={gridStyle()}>
            <For each={getBoard().tiles()}>
              {(item, _index) => <Tile tile={item}></Tile>}
            </For>
          </div>
        </div>
      </div >
    </>
  )
}

export default App


const Print = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" fill="currentcolor" stroke-width="0" width="24" height="24" viewBox="0 0 24 24">
    <path d="M21.5 10H18V5.6L13.3 1H6v9H2.5A1.5 1.5 0 0 0 1 11.5v8A1.5 1.5 0 0 0 2.5 21H6v2h12v-2h3.5a1.5 1.5 0 0 0 1.5-1.5v-8a1.5 1.5 0 0 0-1.5-1.5M17 6h-4V2zM7 2h5v5h5v3H7zm10 20H7v-5h10zm5-2.5a.5.5 0 0 1-.5.5H18v-2h1v-2H5v2h1v2H2.5a.5.5 0 0 1-.5-.5v-8a.5.5 0 0 1 .5-.5h19a.5.5 0 0 1 .5.5zm-7 .5v1H9v-1zm-6-1v-1h6v1zm10-7h1v1h-1z" />
    <path fill="none" d="M0 0h24v24H0z" />
  </svg>;
}

const Warning = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width={2} stroke-linecap="round" stroke-linejoin="round" > <path d="M12 9v4" /> <path d="M10.363 3.591l-8.106 13.534a1.914 1.914 0 0 0 1.636 2.871h16.214a1.914 1.914 0 0 0 1.636 -2.87l-8.106 -13.536a1.914 1.914 0 0 0 -3.274 0z" /> <path d="M12 16h.01" /> </svg>;
}