import { createSignal, Signal } from 'solid-js';
import { BoardTile, TileType, getNextRotation, getPreviousRotation } from './models/board';
import { ClickAction, getSelectedClickAction } from './App';

interface TileProps {
    tile: Signal<BoardTile>
}

export const Tile = (props: TileProps) => {
    const [getTile, setTile] = props.tile;
    const [getIsHovered, setIsHovered] = createSignal(false);

    function rotateTileRotation(e: MouseEvent) {
        e.preventDefault();
        setTile({ ...getTile(), rotation: e.shiftKey ? getPreviousRotation(getTile().rotation) : getNextRotation(getTile().rotation) });
    }

    function onHandleClick(e: MouseEvent) {
        e.preventDefault();

        const clickAction = getSelectedClickAction();
        if (clickAction === ClickAction.SetTileType_Empty) {
            setTile({ ...getTile(), type: TileType.Empty });
        } else if (clickAction === ClickAction.SetTileType_ColorGreen) {
            setTile({ ...getTile(), type: TileType.ColorGreen });
        } else if (clickAction === ClickAction.SetTileType_ColorRed) {
            setTile({ ...getTile(), type: TileType.ColorRed });
        } else if (clickAction === ClickAction.SetTileType_ColorBlue) {
            setTile({ ...getTile(), type: TileType.ColorBlue });
        } else if (clickAction === ClickAction.SetTileType_ColorYellow) {
            setTile({ ...getTile(), type: TileType.ColorYellow });
        } else if (clickAction === ClickAction.SetTileType_ArrowSingle) {
            setTile({ ...getTile(), type: TileType.ArrowSingle });
        } else if (clickAction === ClickAction.SetTileType_ArrowDoubleFourtyFive) {
            setTile({ ...getTile(), type: TileType.ArrowDoubleFourtyFive });
        } else if (clickAction === ClickAction.SetTileType_ArrowDoubleNinety) {
            setTile({ ...getTile(), type: TileType.ArrowDoubleNinety });
        } else if (clickAction === ClickAction.SetTileType_ArrowDoubleOneHundredThirtyFive) {
            setTile({ ...getTile(), type: TileType.ArrowDoubleOneHundredThirtyFive });
        } else if (clickAction === ClickAction.SetTileType_ArrowDoubleOneHundredEighty) {
            setTile({ ...getTile(), type: TileType.ArrowDoubleOneHundredEighty });
        } else if (clickAction === ClickAction.SetTileType_ArrowSkip) {
            setTile({ ...getTile(), type: TileType.ArrowSkip });
        } else if (clickAction === ClickAction.RotateCW) {
            setTile({ ...getTile(), rotation: getNextRotation(getTile().rotation) });
        } else if (clickAction === ClickAction.RotateCCW) {
            setTile({ ...getTile(), rotation: getPreviousRotation(getTile().rotation) });
        }
    }

    const renderTileContent = () => {
        return (!getIsHovered() || getSelectedClickAction() !== ClickAction.RotateCW || getSelectedClickAction() !== ClickAction.RotateCCW)
    }

    const getTileContent = () => {
        if (getIsHovered() && getSelectedClickAction() !== ClickAction.RotateCW && getSelectedClickAction() !== ClickAction.RotateCCW) {
            return <></>;
        }

        const tileTypeToIcon = {
            [TileType.ColorGreen]: TileContentGreen,
            [TileType.ColorRed]: TileContentRed,
            [TileType.ColorBlue]: TileContentBlue,
            [TileType.ColorYellow]: TileContentYellow,
            [TileType.Empty]: TileContentEmpty,
            [TileType.ArrowSingle]: TileContentArrowSingle,
            [TileType.ArrowDoubleNinety]: TileContentArrowDoubleNinety,
            [TileType.ArrowDoubleOneHundredEighty]: TileContentArrowDoubleOneHundredEighty,
            [TileType.ArrowDoubleFourtyFive]: TileContentArrowDoubleFourtyFive,
            [TileType.ArrowDoubleOneHundredThirtyFive]: TileContentArrowDoubleOneHundredThirtyFive,
            [TileType.ArrowSkip]: TileContentSkip,
        }

        if (tileTypeToIcon[getTile().type]) {
            return tileTypeToIcon[getTile().type]();
        }

        console.log(`not yet implemented: getArrowIcon(${getTile().type})`);
        return <></>;
    }

    const getHoverContent = () => {
        const selectedTileType = getSelectedClickAction();
        switch (selectedTileType) {
            case ClickAction.SetTileType_Empty:
                return <></>;
            case ClickAction.SetTileType_ColorGreen:
                return <TileContentGreen />;
            case ClickAction.SetTileType_ColorRed:
                return <TileContentRed />;
            case ClickAction.SetTileType_ColorBlue:
                return <TileContentBlue />;
            case ClickAction.SetTileType_ColorYellow:
                return <TileContentYellow />;
            case ClickAction.SetTileType_ArrowSingle:
                return <TileContentArrowSingle />;
            case ClickAction.SetTileType_ArrowDoubleFourtyFive:
                return <TileContentArrowDoubleFourtyFive />;
            case ClickAction.SetTileType_ArrowDoubleNinety:
                return <TileContentArrowDoubleNinety />;
            case ClickAction.SetTileType_ArrowDoubleOneHundredThirtyFive:
                return <TileContentArrowDoubleOneHundredThirtyFive />;
            case ClickAction.SetTileType_ArrowDoubleOneHundredEighty:
                return <TileContentArrowDoubleOneHundredEighty />;
            case ClickAction.SetTileType_ArrowSkip:
                return <TileContentSkip />;

            case ClickAction.RotateCW:
                return <></>; // todo: visually represented by changing the cursor icon? Or preview the rotation?
            case ClickAction.RotateCCW:
                return <></>; // todo: visually represented by changing the cursor icon? Or preview the rotation?

            default:
                console.log(`not yet implemented: getHoverArrowIcon(${selectedTileType})`);
                return <></>;
        }
    }
    const getRotationTransform = () => {
        return `rotate(${getTile().rotation})`;
    }

    return (
        <div class="tile" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} onClick={onHandleClick} onContextMenu={rotateTileRotation}>
            <div class="tile-content" style={{ transform: getRotationTransform(), transition: 'transform 0.15s ease-in-out' }}>
                {renderTileContent() && getTileContent()}
            </div>
            <div class="hover-content">
                {getHoverContent()}
            </div>
        </div>
    )
}

export const TileContentGreen = () => {
    return <div style={{ background: 'green', width: '100%', height: '100%' }}></div>;
}

export const TileContentRed = () => {
    return <div style={{ background: 'red', width: '100%', height: '100%' }}></div>;
}

export const TileContentBlue = () => {
    return <div style={{ background: 'blue', width: '100%', height: '100%' }}></div>;
}

export const TileContentYellow = () => {
    return <div style={{ background: 'yellow', width: '100%', height: '100%' }}></div>;
}

export const TileContentEmpty = () => {
    return <></>;
}

export const TileContentArrowSingle = () => {
    return <svg style={{ margin: 'auto' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" width={24} height={24} stroke-width={2}>
        <path d="M12 3l0 20"></path><path d="M16 6l-4 -4"></path> <path d="M8 6l4 -4"></path>
    </svg>;
}

export const TileContentArrowDoubleNinety = () => {
    return <svg style={{ margin: 'auto' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" width={24} height={24} stroke-width={2}>
        <path d="M8 2l0 18"></path><path d="M12 6l-4 -4"></path><path d="M4 6l4 -4"></path>
        <path d="M9 20l14 0"></path> <path d="M19 24l4 -4"></path><path d="M19 16l4 4"></path>
    </svg>;
}

export const TileContentArrowDoubleOneHundredEighty = () => {
    return <svg style={{ margin: 'auto' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" width={24} height={24} stroke-width={2}>
        <path d="M12 3l0 20"></path><path d="M16 6l-4 -4"></path> <path d="M8 6l4 -4"></path>
        <path d="M12 3l0 20"></path><path d="M16 19l-4 4"></path><path d="M8 19l4 4"></path>
    </svg>
}

export const TileContentArrowDoubleFourtyFive = () => {
    return <svg style={{ margin: 'auto' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" width={24} height={24} stroke-width={2}>
        <path d="M8 2l0 18"></path><path d="M12 6l-4 -4"></path><path d="M4 6l4 -4"></path>
        <path d="M8 20l12 -12" /><path d="M16.3 6l5.66 0l0 5.66" />
    </svg>;
}

export const TileContentArrowDoubleOneHundredThirtyFive = () => {
    return <svg style={{ margin: 'auto' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" width={24} height={24} stroke-width={2}>
        <path d="M8 4l0 8"></path><path d="M12 6l-4 -4"></path> <path d="M4 6l4 -4"></path>
        <path d="M8 12l8 8"></path><path d="M16 20l0 -5.66"></path><path d="M16 20l-5.66 0"></path>
    </svg>;
}

export const TileContentSkip = () => {
    return <svg style={{ margin: 'auto' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" width={24} height={24} stroke-width={2}>
        <path d="M12 3l0 20"></path>
        <path d="M16 6l-4 -4"></path> <path d="M8 6l4 -4"></path>
        <path d="M16 11l-4 -4"></path> <path d="M8 11l4 -4"></path>
    </svg>;
}

export const RotateCW = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentcolor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24"><path d="M4 11a8 8 0 1 1 1 4m-1 5v-5h5" /></svg>;
}

export const RotateCCW = () => {
    return <svg style={{ transform: 'scaleX(-1)' }} xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width={2} stroke-linecap="round" stroke-linejoin="round" ><path d="M4.05 11a8 8 0 1 1 .5 4m-.5 5v-5h5" /></svg>;
}
