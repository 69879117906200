import { createSignal } from "solid-js";
import type { Accessor, Signal } from "solid-js";

const DEFAULT_BOARD_SIZE = { width: 12, height: 14 };

interface Board {
    tiles: Accessor<Signal<BoardTile>[]>;
}

export interface BoardTile {
    type: TileType;
    rotation: Rotation;
}

export enum TileType {
    Empty = 'empty',
    ColorGreen = 'color-green',
    ColorRed = 'color-red',
    ColorBlue = 'color-blue',
    ColorYellow = 'color-yellow',
    ArrowSingle = 'arrow-single',
    ArrowDoubleFourtyFive = 'arrow-double-45',
    ArrowDoubleNinety = 'arrow-double-90',
    ArrowDoubleOneHundredThirtyFive = 'arrow-double-135',
    ArrowDoubleOneHundredEighty = 'arrow-double-180',
    ArrowSkip = 'arrow-skip',
}
const TileTypes = [TileType.Empty, TileType.ColorGreen, TileType.ColorRed, TileType.ColorBlue, TileType.ColorYellow, TileType.ArrowSingle, TileType.ArrowDoubleFourtyFive, TileType.ArrowDoubleNinety, TileType.ArrowDoubleOneHundredThirtyFive, TileType.ArrowDoubleOneHundredEighty, TileType.ArrowSkip];
export const getNextTileType = (tileType: TileType): TileType => {
    const index = TileTypes.indexOf(tileType);
    return TileTypes[(index + 1) % TileTypes.length];
}

const QuickSelectTileTypes = [TileType.Empty, TileType.ArrowSingle, TileType.ArrowDoubleFourtyFive, TileType.ArrowDoubleNinety, TileType.ArrowDoubleOneHundredThirtyFive, TileType.ArrowDoubleOneHundredEighty, TileType.ArrowSkip];
export const getNextQuickSelectTileType = (tileType: TileType): TileType => {
    const index = QuickSelectTileTypes.indexOf(tileType);
    if (index === -1) {
        return TileType.ArrowSingle;
    }

    return QuickSelectTileTypes[(index + 1) % QuickSelectTileTypes.length];
}

export enum Rotation {
    Zero = "0deg",
    FourtyFive = "45deg",
    Ninety = "90deg",
    OneHundredThirtyFive = "135deg",
    OneHundredEighty = "180deg",
    TwoHundredTwentyFive = "225deg",
    TwoHundredSeventy = "270deg",
    ThreeHundredFifteen = "315deg",
}

const RotationKeys = [Rotation.Zero, Rotation.FourtyFive, Rotation.Ninety, Rotation.OneHundredThirtyFive, Rotation.OneHundredEighty, Rotation.TwoHundredTwentyFive, Rotation.TwoHundredSeventy, Rotation.ThreeHundredFifteen];
export const getNextRotation = (rotation: Rotation): Rotation => {
    const index = RotationKeys.indexOf(rotation);
    return RotationKeys[(index + 1) % RotationKeys.length];
}
export const getPreviousRotation = (rotation: Rotation): Rotation => {
    const index = RotationKeys.indexOf(rotation);
    return RotationKeys[(index - 1 + RotationKeys.length) % RotationKeys.length];
}


const boardSizeSignal = createSignal(DEFAULT_BOARD_SIZE);
export const [getBoardSize, setBoardSize] = boardSizeSignal;

const getSavedOrNewBoard = (): Board => {
    const size = getBoardSize();

    // Read localStorage to see if there's a saved board
    const serializedBoard = localStorage.getItem('board');
    if (serializedBoard) {
        const board = deserializeBoard(serializedBoard);
        if (board.tiles().length === size.height * size.width) {
            return board;
        }
    }
    return getNewBoard();
}

function getNewBoard(): Board {
    const tiles = [];
    const size = getBoardSize();
    for (let i = 0; i < size.height * size.width; i++) {
        const tile = createSignal({ type: TileType.Empty, rotation: Rotation.Zero });
        tiles.push(tile);
    }

    const bottomLeftTileIndex = size.width * (size.height - 1);
    const topRightTileIndex = size.width - 1;
    tiles[bottomLeftTileIndex][1]({ type: TileType.ColorGreen, rotation: Rotation.Zero });
    tiles[topRightTileIndex][1]({ type: TileType.ColorRed, rotation: Rotation.Zero });

    return { tiles: createSignal(tiles)[0] };
}

const [getBoard, setBoard] = createSignal(getSavedOrNewBoard());
export { getBoard };

export function serializeBoard(board: Board): string {
    const tiles = [];
    for (const tile of board.tiles()) {
        const [getTile, _setTile] = tile;
        tiles.push(getTile())
    }
    const jsBoard = { tiles };
    return JSON.stringify(jsBoard);
}

function deserializeBoard(serializedBoard: string): Board {
    const jsBoard = JSON.parse(serializedBoard);

    const tiles: Signal<BoardTile>[] = [];
    for (const tile of jsBoard.tiles) {
        tiles.push(createSignal(tile));
    }

    return { tiles: createSignal(tiles)[0] };
}

export const resetBoard = () => {
    if (confirm('Are you sure you want to reset the board?')) {
        setBoard(getNewBoard());
    }
}
